import axiosClient from "./clients/axiosClient";
const resource = "/local-governments";
const lgId = process.env.VUE_APP_LOCAL_GOV_ID;

export default {
  getPolicies(params = "") {
    return axiosClient.get(`${resource}/${lgId}/policies${params}`);
  },
  getDecisions(params = "") {
    return axiosClient.get(`${resource}/${lgId}/decisions${params}`);
  },
  getServices(params = "") {
    return axiosClient.get(`${resource}/${lgId}/services-v2${params}`);
  },
  getServicesFiltered(params = "") {
    return axiosClient.get(`${resource}/${lgId}/services-v2/filters${params}`);
  },
  getServicesMetadata(params = "") {
    return axiosClient.get(`${resource}/${lgId}/services-v2/metadata${params}`);
  },
  getServiceDetails(id) {
    return axiosClient.get(`${resource}/${lgId}/services-v2/${id}`);
  },
};
