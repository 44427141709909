<template>
  <div class="policy-wrapper">
    <div class="mb-32 component__header">
      <div class="container">
        <h1>नीतिहरु</h1>
      </div>
    </div>
    <div class="container policy__content">
      <div v-if="years.length">
        <div class="flex flex-policy-pdf">
          <div class="policy__filter multiselect__full-width">
            <Multiselect
              v-model="selected_year"
              :options="years"
              :placeholder="default_year"
              :canClear="false"
            />
          </div>
          <button
            @click="$store.dispatch('windowPrint')"
            class="btn download-pdf"
          >
            PDF डाउनलोड गर्नुहोस्
          </button>
        </div>
        <div
          v-for="(policy, index) in policies"
          :key="index"
          class="policy__card mb-40"
        >
          <h3 class="policy__title mb-16">{{ policy.title }}</h3>
          <div class="policy__card--inner">
            <div
              v-for="(ele, index) in policy.policies"
              :key="index"
              class="mb-24"
            >
              <h4 class="policy__subtitle mb-16">{{ ele.sub_title }}</h4>
              <ul>
                <li
                  v-for="(li_ele, idx) in ele.descriptions"
                  :key="idx"
                  class="policy__description mb-16"
                >
                  {{ englishToNepaliNumber(idx + 1) }}. {{ li_ele }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <NoDataFound v-else />
    </div>
  </div>
  <PdfDownload ref="pdf">
    <template v-slot:content>
      <div class="flex flex-title">
        <h1 class="mb-16">नीतिहरु</h1>
        <span class="selected-filter">{{ selected_year }}</span>
      </div>
      <div
        v-for="(policy, index) in policies"
        :key="index"
        class="policy__card mb-40"
      >
        <h2 class="policy__title mb-16">{{ policy.title }}</h2>
        <div class="policy__card--inner">
          <div
            v-for="(ele, index) in policy.policies"
            :key="index"
            class="mb-24"
          >
            <h3 class="policy__subtitle mb-16">{{ ele.sub_title }}</h3>
            <ul>
              <li
                v-for="(li_ele, idx) in ele.descriptions"
                :key="idx"
                class="policy__description mb-16"
              >
                {{ englishToNepaliNumber(idx + 1) }}. {{ li_ele }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </PdfDownload>
</template>
<script>
import { defineAsyncComponent } from "vue";
import LocalGovernmentRepo from "../../repositories/LocalGovernmentRepository";
import Multiselect from "@vueform/multiselect";
import PdfDownload from "./../../components/shared/PdfDownload.vue";
import { getEnglishNumber } from "./../../helper/helper";
import { englishToNepaliNumber } from "nepali-number";
const NoDataFound = defineAsyncComponent(() =>
  import("../../components/NoDataFound.vue")
);

export default {
  components: {
    Multiselect,
    NoDataFound,
    PdfDownload,
  },
  data() {
    return {
      englishToNepaliNumber,
      policies: {},
      years: {},
      selected_year: "",
      default_year: "",
      default: {
        placeholder: "multiselect-placeholder",
      },
    };
  },
  watch: {
    selected_year: {
      handler: "getFilteredPolicies",
    },
  },
  async mounted() {
    let { data } = await LocalGovernmentRepo.getPolicies();
    this.policies = data.data;
    this.years = this.getYearsOption(data.metadata.years);
    this.selected_year = data.metadata.years[this.years.length - 1];
    this.default_year = "आ. व. " + data.metadata.years[this.years.length - 1];
    this.$store.dispatch("setLoading", false);
  },
  methods: {
    getYearsOption(years) {
      let tempYear = [];

      for (const year in years) {
        tempYear.push({ value: years[year], label: "आ. व. " + years[year] });
      }

      return tempYear;
    },
    async getFilteredPolicies() {
      this.selected_year = this.selected_year
        ? this.selected_year
        : this.years[this.years.length - 1].value;
      let year = this.selected_year ? getEnglishNumber(this.selected_year) : "";
      let { data } = await LocalGovernmentRepo.getPolicies(
        `?fiscalYear=${year}`
      );
      this.policies = data.data;
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
.policy {
  &-wrapper {
    padding-bottom: 80px;
  }
  &__content {
    max-width: 875px;
    padding: 0 20px;
    position: relative;
    ul li {
      &:only-child {
        margin-bottom: 0;
      }
    }
  }
  &__card {
    &--inner {
      background: $neutrals-white;
      border-radius: 20px;
      padding: 20px 40px;
    }
  }
}
</style>
