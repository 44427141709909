<template>
  <div class="print-css">
    <div class="logo mb-16">
      <a href="/" class="flex logo__link">
        <img
          src="../../assets/images/nepal-gov.png"
          alt="logo"
          class="logo__img"
          width="58"
          height="49"
        />
        <span class="logo__text">
          <span style="display: block">{{ $store.state.title }} </span>
          <span style="display: block">{{ $store.state.district }} </span>
          <span style="display: block">{{ $store.state.slogan }} </span>
        </span>
      </a>
    </div>
    <!-- template slots will be rendered here -->
    <slot name="content" />
  </div>
</template>

<script>
export default {
  name: "pdf-download",
};
</script>

<style lang="scss" scoped></style>
